import type {Theme} from '@mui/material';
import {Alert, Stack} from '@mui/material';
import type {SxProps} from '@mui/system';
import type {Customer} from '@/types/customer';

type Props = {
    customer : Customer;
    sx ?: SxProps<Theme>;
};

const Notifications = ({customer, sx} : Props) : JSX.Element | null => {
    const messages = customer.messages ?? [];

    if (messages.length === 0) {
        return null;
    }

    return (
        <Stack spacing={2} sx={sx}>
            {messages.map((message, index) => (
                <Alert key={index} severity={message.severity}>
                    {message.content}
                </Alert>
            ))}
        </Stack>
    );
};

export default Notifications;
