import {DateTimeFormatter, LocalDate, ZonedDateTime} from '@js-joda/core';
import DecimalJs from 'decimal.js';
import type {Customer, LineItem, RawCustomer, RawLineItem} from '@/types/customer';

const mapRawToLineItem = (raw : RawLineItem) : LineItem => ({
    ...raw,
    taxRate: new DecimalJs(raw.taxRate),
});

export const mapRawToCustomer = (raw : RawCustomer) : Customer => ({
    ...raw,
    deliveryDate: LocalDate.parse(raw.deliveryDate),
    nextDeliveryDate: raw.nextDeliveryDate ? LocalDate.parse(raw.nextDeliveryDate) : undefined,
    cutOff: {
        ...raw.cutOff,
        at: ZonedDateTime.parse(raw.cutOff.at, DateTimeFormatter.ISO_ZONED_DATE_TIME),
    },
    lineItems: raw.lineItems.map(mapRawToLineItem),
});
