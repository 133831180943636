import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';

export const currencyFormatter = new Intl.NumberFormat(
    'en-US',
    {style: 'currency', currency: 'USD'}
);

export const percentFormatter = new Intl.NumberFormat(
    'en-US',
    {style: 'percent', maximumFractionDigits: 4}
);

export const dateFormatter = DateTimeFormatter.ofPattern('EEEE, M/d/yyyy').withLocale(Locale.US);

export const dateTimeFormatter = DateTimeFormatter.ofPattern('EEEE, M/d/yyyy \'at\' HH:mm (Z)').withLocale(Locale.US);
