import {Alert, Typography} from '@mui/material';
import CurrentOrder from '@/pages/Home/CurrentOrder';
import OrderForm from '@/pages/Home/OrderForm';
import type {Customer} from '@/types/customer';
import type {Order} from '@/types/order';
import {dateFormatter} from '@/utils/format';

type Props = {
    customer : Customer;
    order : Order | null;
};

const OrderView = ({customer, order} : Props) : JSX.Element => {
    if (!order && customer.cutOff.reached) {
        return (
            <Alert severity="error">
                <Typography>
                    You've passed the deadline in which you can place an order for the current delivery date.
                </Typography>
                <Typography>
                    Please wait until the next delivery window.
                </Typography>
                {customer.nextDeliveryDate && (
                    <Typography>
                        Your next delivery date is {customer.nextDeliveryDate.format(dateFormatter)}.
                    </Typography>
                )}
            </Alert>
        );
    }

    if (!order) {
        return <OrderForm customer={customer}/>;
    }

    return <CurrentOrder customer={customer} order={order}/>;
};

export default OrderView;
