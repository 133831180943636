import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToOrder} from '@/mappers/order';
import type {Order, RawOrder} from '@/types/order';
import {apiUrl} from '@/utils/api';

export const useOrderQuery = (customerId = 'self', orderId = 'current') : UseQueryResult<Order | null> => {
    const fetch = useOidcFetch();

    return useQuery(['customer', customerId, 'order', orderId], async ({signal}) => {
        const response = await fetch(apiUrl(`/customers/${customerId}/orders/${orderId}`).toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error(`Unable to fetch order ${orderId} of customer ${customerId}`);
        }

        if (response.status === 204) {
            return null;
        }

        const raw = await response.json() as RawOrder;
        return mapRawToOrder(raw);
    });
};
