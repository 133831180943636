import {useAuth0} from '@auth0/auth0-react';
import {useCallback} from 'react';

const useOidcFetch = () : typeof fetch => {
    const {getAccessTokenSilently} = useAuth0();

    return useCallback(async (input : RequestInfo | URL, init ?: RequestInit) => {
        const accessToken = await getAccessTokenSilently();

        if (!init) {
            init = {};
        }

        init.headers = new Headers(init.headers);
        init.headers.set('Authorization', `Bearer ${accessToken}`);

        return await fetch(input as Request | string, init);
    }, [getAccessTokenSilently]);
};

export default useOidcFetch;
