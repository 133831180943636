import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToOrder} from '@/mappers/order';
import type {ApiError} from '@/types/api';
import type {Order, OrderLineItem, RawOrder} from '@/types/order';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type CreateOrderValues = {
    lineItems : OrderLineItem[];
    orderedBy : string;
    poNumber : string;
    note : string;
};

export const useCreateOrderMutation = (customerId = 'self') : UseMutationResult<Order, unknown, CreateOrderValues> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : CreateOrderValues) => {
        const response = await fetch(apiUrl(`/customers/${customerId}/orders`).toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const raw = await response.json() as RawOrder;
            return mapRawToOrder(raw);
        }

        const error = await response.json() as ApiError;

        if (error.status === 409 && error.hint === 'order-exists') {
            throw new SubmissionError('An order for this delivery date already exists');
        }

        if (error.status === 409 && error.hint === 'cut-off-reached') {
            throw new SubmissionError('You\'ve reached the deadline for this order');
        }

        throw new SubmissionError('Failed to create order');
    }, {
        onSuccess: order => {
            queryClient.setQueryData(['customer', customerId, 'order', 'current'], order);
        },
    });
};
