import {LoadingButton} from '@mui/lab';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import type {ConfirmProps} from 'react-confirm-hook';
import type {Customer} from '@/types/customer';
import type {OrderLineItem} from '@/types/order';
import {currencyFormatter} from '@/utils/format';
import {mergeOrder} from '@/utils/order';

type ConfirmDialogProps = ConfirmProps & {
    customer : Customer;
    orderLineItems : OrderLineItem[];
};

const OrderConfirmDialog = ({
    customer,
    orderLineItems,
    open,
    onConfirm,
    onCancel,
    isConfirming,
} : ConfirmDialogProps) : JSX.Element => {
    const mergedOrder = mergeOrder(customer, orderLineItems);

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Confirm Order</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    You are about to place an order for {currencyFormatter.format(mergedOrder.final / 100)}.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={onCancel}
                    disabled={isConfirming}
                >
                    Cancel
                </Button>
                <LoadingButton
                    onClick={onConfirm}
                    color="primary"
                    loading={isConfirming}
                >
                    Place Order
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default OrderConfirmDialog;
