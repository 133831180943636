import {useAuth0} from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import type {Theme} from '@mui/material';
import {Button, Divider, IconButton, Popover, Stack, Typography} from '@mui/material';
import type {SxProps} from '@mui/system';
import {bindPopover, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import {namespace} from '@/utils/jwt';

type Props = {
    sx ?: SxProps<Theme>;
};

const AccountButton = ({sx} : Props) : JSX.Element | null => {
    const {user, logout} = useAuth0();
    const popupState = usePopupState({variant: 'popover', popupId: 'accountPopover'});

    if (!user) {
        throw new Error('Component can only be used with authenticated user');
    }

    const customerId = user[`${namespace}/customerId`] as number;
    const customerName = user[`${namespace}/customerName`] as string;

    return (
        <>
            <IconButton color="inherit" sx={sx} {...bindTrigger(popupState)}>
                <AccountCircleIcon/>
            </IconButton>

            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Stack spacing={2} sx={{p: 2, minWidth: '300px'}}>
                    <Typography>
                        {customerName}<br/>
                        #{customerId}
                    </Typography>
                    <Divider/>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                            logout({returnTo: window.location.origin});
                        }}
                    >
                        Logout
                    </Button>
                </Stack>
            </Popover>
        </>
    );
};

export default AccountButton;
