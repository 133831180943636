import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Auth0Provider} from '@auth0/auth0-react';
import {createTheme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SnackbarProvider} from 'notistack';
import {StrictMode} from 'react';
import {ConfirmProvider} from 'react-confirm-hook';
import {render} from 'react-dom';
import App from './App';
import {OidcSecure} from '@/components/Oidc';
import '@js-joda/timezone';

const theme = createTheme({
    spacing: 4,
    palette: {
        primary: {
            main: '#d14627',
        },
        secondary: {
            main: '#2196f3',
        },
    },
});

const queryClient = new QueryClient();

render(
    (
        <StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Auth0Provider
                    domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
                    clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
                    redirectUri={window.location.origin}
                    audience={import.meta.env.VITE_APP_AUTH0_AUDIENCE}
                    useRefreshTokens
                >
                    <OidcSecure>
                        <QueryClientProvider client={queryClient}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <SnackbarProvider maxSnack={1}>
                                    <ConfirmProvider>
                                        <App/>
                                    </ConfirmProvider>
                                </SnackbarProvider>
                            </LocalizationProvider>
                        </QueryClientProvider>
                    </OidcSecure>
                </Auth0Provider>
            </ThemeProvider>
        </StrictMode>
    ),
    document.getElementById('root')
);
