import {TableCell, TableRow} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import type {UseFormReturn} from 'react-hook-form';
import type {FormValues} from './OrderForm';
import type {LineItem} from '@/types/customer';
import {percentFormatter, currencyFormatter} from '@/utils/format';

type Props = {
    form : UseFormReturn<FormValues>;
    item : LineItem;
};

const LineItemRow = ({form, item} : Props) : JSX.Element => {
    const rawQuantity = form.watch(`quantities.item_${item.id}`) as unknown as string | undefined;
    let quantity = rawQuantity ? parseInt(rawQuantity, 10) : 0;

    if (Number.isNaN(quantity)) {
        quantity = 0;
    }

    return (
        <TableRow>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell align="right">{item.normalQuantity}</TableCell>
            <TableCell align="right">{currencyFormatter.format(item.lastPrice / 100)}</TableCell>
            <TableCell align="right">{percentFormatter.format(item.taxRate.toNumber())}</TableCell>
            <TableCell align="right">
                <RhfTextField
                    size="small"
                    sx={{width: '100%'}}
                    control={form.control}
                    name={`quantities.item_${item.id}`}
                    label="Quantity"
                    inputProps={{inputMode: 'numeric'}}
                    autoComplete="off"
                />
            </TableCell>
            <TableCell align="right">{currencyFormatter.format(quantity * item.lastPrice / 100)}</TableCell>
        </TableRow>
    );
};

export default LineItemRow;
