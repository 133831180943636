import {useAuth0} from '@auth0/auth0-react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Alert, Button, Card, CardContent, Stack} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {RhfTextField} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import {useConfirm} from 'react-confirm-hook';
import type {FieldError} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import LineItemTable from './LineItemTable';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateOrderMutation} from '@/mutations/order';
import OrderConfirmDialog from '@/pages/Home/OrderForm/OrderConfirmDialog';
import type {Customer} from '@/types/customer';
import {dateFormatter, dateTimeFormatter, currencyFormatter} from '@/utils/format';
import {namespace} from '@/utils/jwt';
import {errorMap} from '@/utils/zod';

type Props = {
    customer : Customer;
};

const schema = z.object({
    orderedBy: z.string().trim().max(10, {message: 'Must be 10 or fewer characters long'}),
    poNumber: z.string().trim().default(''),
    note: z.string().trim().trim().default(''),
    quantities: z.record(
        z.string()
            .trim()
            .regex(/^\d*$/, 'Must be a number')
            .transform(value => value === '' ? '0' : value)
            .default('0')
            .transform(value => parseInt(value, 10)),
    ).transform(quantities => Object.fromEntries(Object.entries(quantities).map(
        ([key, value]) => [key.replace(/^item_/, ''), value]
    ))),
});

export type FormValues = z.infer<typeof schema>;

const OrderForm = ({customer} : Props) : JSX.Element => {
    const confirm = useConfirm(OrderConfirmDialog);
    const handleMutation = useHandleMutation();
    const createOrderMutation = useCreateOrderMutation();
    const {enqueueSnackbar} = useSnackbar();
    const {user} = useAuth0();

    if (!user) {
        throw new Error('Component can only be used with authenticated user');
    }

    const customerId = user[`${namespace}/customerId`] as number;
    const customerName = user[`${namespace}/customerName`] as string;

    const customizedSchema = schema.superRefine((order, context) => {
        const subtotal = customer.lineItems.reduce((subtotal, item) => {
            const quantity = item.id in order.quantities ? order.quantities[item.id] : 0;
            return subtotal + item.lastPrice * quantity;
        }, 0);

        if (subtotal < customer.totalAmountMinimum) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Your subtotal must be at least ${
                    currencyFormatter.format(customer.totalAmountMinimum / 100)
                }`,
            });
        }
    });

    const form = useForm<FormValues>({
        resolver: zodResolver(customizedSchema, {errorMap}),
    });

    const handleSubmit = (values : FormValues) => {
        const orderLineItems = Object.entries(values.quantities).map(([id, quantity]) => ({id, quantity}));

        confirm({
            customer,
            orderLineItems,
            onConfirm: async () => {
                if ((await handleMutation(createOrderMutation, {
                    lineItems: orderLineItems,
                    orderedBy: values.orderedBy,
                    poNumber: values.poNumber,
                    note: values.note,
                })).success) {
                    enqueueSnackbar(
                        'Your order has been received, you will receive an e-mail when processed.',
                        {variant: 'success'}
                    );
                }
            },
        });
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} md={6}>
                            <strong>Customer Name:</strong> {customerName}
                        </Grid2>
                        <Grid2 xs={12} md={6} sx={{textAlign: {xs: 'left', md: 'right'}}}>
                            <strong>Customer Number:</strong> {customerId}
                        </Grid2>
                        <Grid2 xs={12} md={6}>
                            <strong>Delivery Date:</strong> {customer.deliveryDate.format(dateFormatter)}
                        </Grid2>
                        <Grid2 xs={12} md={6} sx={{textAlign: {xs: 'left', md: 'right'}}}>
                            <strong>Order by:</strong> {customer.cutOff.at.format(dateTimeFormatter)}
                        </Grid2>
                        {customer.nextDeliveryDate && (
                            <Grid2 xs={12} md={6}>
                                <strong>Next Delivery Date:</strong> {customer.nextDeliveryDate.format(dateFormatter)}
                            </Grid2>
                        )}
                    </Grid2>
                </CardContent>
            </Card>

            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                noValidate
            >
                <LineItemTable form={form} customer={customer} sx={{mt: 4, mb: 4}}/>

                {'' in form.formState.errors && (
                    <Alert severity="error" sx={{mb: 4}}>
                        {(form.formState.errors as Record<'', FieldError>)[''].message}
                    </Alert>
                )}

                <Card>
                    <CardContent>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} md={4}>
                                <Stack spacing={2}>
                                    <RhfTextField
                                        control={form.control}
                                        name="orderedBy"
                                        label="Ordered By (name)"
                                        inputProps={{maxLength: 10}}
                                        required
                                    />
                                    <RhfTextField
                                        control={form.control}
                                        name="poNumber"
                                        label='PO Number'
                                    />
                                </Stack>
                            </Grid2>
                            <Grid2 xs={12} md={8}>
                                <RhfTextField
                                    control={form.control}
                                    name="note"
                                    label="Add Note"
                                    multiline
                                    rows={6}
                                    sx={{width: '100%'}}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>

                <Button type="submit" variant="contained" sx={{mt: 4}}>Place Order</Button>
            </form>
        </>
    );
};

export default OrderForm;
