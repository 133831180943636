import {LinearProgress} from '@mui/material';
import Notifications from './Notifications';
import OrderView from './OrderView';
import RetryableError from '@/components/RetryableError';
import {useCustomerQuery} from '@/queries/customer';
import {useOrderQuery} from '@/queries/order';

const Home = () : JSX.Element => {
    const customerQuery = useCustomerQuery();
    const orderQuery = useOrderQuery();

    if (customerQuery.isLoading || orderQuery.isLoading) {
        return (
            <LinearProgress/>
        );
    }

    if (customerQuery.isError) {
        return (
            <RetryableError
                message="Unable to fetch customer information"
                onRetry={() => void customerQuery.refetch()}
            />
        );
    }

    if (orderQuery.isError) {
        return (
            <RetryableError
                message="Unable to fetch current order"
                onRetry={() => void orderQuery.refetch()}
            />
        );
    }

    const customer = customerQuery.data;
    const order = orderQuery.data;

    return (
        <>
            <Notifications customer={customer} sx={{mb: 4}}/>
            <OrderView customer={customer} order={order}/>
        </>
    );
};

export default Home;
