import {useAuth0} from '@auth0/auth0-react';
import {
    Alert, Button,
    Card,
    CardContent,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import type {Customer} from '@/types/customer';
import type {Order} from '@/types/order';
import {currencyFormatter, dateFormatter, percentFormatter} from '@/utils/format';
import {namespace} from '@/utils/jwt';
import {mergeOrder} from '@/utils/order';

type Props = {
    customer : Customer;
    order : Order;
};

const CurrentOrder = ({customer, order} : Props) : JSX.Element => {
    const {user} = useAuth0();

    if (!user) {
        throw new Error('Component can only be used with authenticated user');
    }

    const customerId = user[`${namespace}/customerId`] as number;
    const customerName = user[`${namespace}/customerName`] as string;
    const mergedOrder = mergeOrder(customer, order.lineItems);

    return (
        <>
            <TableContainer component={Paper} sx={{mb: 4}}>
                <Table sx={{minWidth: 650}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item Number</TableCell>
                            <TableCell>Item Description</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Tax Rate</TableCell>
                            <TableCell align="right" sx={{width: '140px'}}>Quantity</TableCell>
                            <TableCell align="right" sx={{width: '110px'}}>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mergedOrder.lineItems.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(item.lastPrice / 100)}</TableCell>
                                <TableCell align="right">{percentFormatter.format(item.taxRate.toNumber())}</TableCell>
                                <TableCell align="right">{item.quantity}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(item.totalPrice / 100)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={5} align="right">
                                Subtotal:
                            </TableCell>
                            <TableCell align="right">
                                {currencyFormatter.format(mergedOrder.subtotal / 100)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5} align="right">
                                Sales Tax:
                            </TableCell>
                            <TableCell align="right">
                                {currencyFormatter.format(mergedOrder.salesTax / 100)}
                            </TableCell>
                        </TableRow>
                        {mergedOrder.fuelSurcharge > 0 && (
                            <TableRow>
                                <TableCell colSpan={5} align="right">
                                    Fuel Surcharge:
                                </TableCell>
                                <TableCell align="right">
                                    {currencyFormatter.format(mergedOrder.fuelSurcharge / 100)}
                                </TableCell>
                            </TableRow>
                        )}
                        {mergedOrder.smallOrderFee > 0 && (
                            <TableRow>
                                <TableCell colSpan={5} align="right">
                                    Fee on orders below {currencyFormatter.format(customer.smallOrderFee.limit / 100)}:
                                </TableCell>
                                <TableCell align="right">
                                    {currencyFormatter.format(mergedOrder.smallOrderFee / 100)}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell colSpan={5} align="right" sx={{borderBottom: 'none'}}>
                                <strong>Order Total:</strong>
                            </TableCell>
                            <TableCell align="right" sx={{borderBottom: 'none'}}>
                                <strong>{currencyFormatter.format(mergedOrder.final / 100)}</strong>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            <Card sx={{mb: 4}}>
                <CardContent>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} md={6}>
                            <Stack spacing={2}>
                                <Typography>
                                    <strong>Customer Name:</strong> {customerName}
                                </Typography>
                                <Typography>
                                    <strong>Customer Number:</strong> {customerId}
                                </Typography>
                                {order.poNumber !== '' && (
                                    <Typography>
                                        <strong>PO number:</strong> {order.poNumber}
                                    </Typography>
                                )}
                                {order.note !== '' && (
                                    <Typography>
                                        <strong>Note:</strong><br/>{order.poNumber}
                                    </Typography>
                                )}
                            </Stack>
                        </Grid2>
                        <Grid2 xs={12} md={6} sx={{textAlign: {xs: 'left', md: 'right'}}}>
                            <Stack spacing={2}>
                                <Typography>
                                    <strong>Delivery Date:</strong> {customer.deliveryDate.format(dateFormatter)}
                                </Typography>
                                <Typography>
                                    <strong>Ordered by:</strong> {order.orderedBy}
                                </Typography>
                                {customer.nextDeliveryDate && (
                                    <Typography>
                                        <strong>Next Delivery Date:</strong>
                                        {' '}{customer.nextDeliveryDate.format(dateFormatter)}
                                    </Typography>
                                )}
                            </Stack>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>

            <Alert severity="success" action={(
                <Button color="inherit" size="small" onClick={window.print} sx={{displayPrint: 'none'}}>
                    Print
                </Button>
            )}>
                Your order has been received, you will receive an e-mail when processed.
            </Alert>
        </>
    );
};

export default CurrentOrder;
