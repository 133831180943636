import type {Theme} from '@mui/material';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import type {SxProps} from '@mui/system';
import type {UseFormReturn} from 'react-hook-form';
import LineItemRow from './LineItemRow';
import type {FormValues} from './OrderForm';
import LineItemFooter from '@/pages/Home/OrderForm/LineItemFooter';
import type {Customer} from '@/types/customer';

type Props = {
    form : UseFormReturn<FormValues>;
    customer : Customer;
    sx ?: SxProps<Theme>;
};

const LineItemTable = ({form, customer, sx} : Props) : JSX.Element => {
    return (
        <TableContainer sx={sx} component={Paper}>
            <Table sx={{minWidth: 650}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Item Number</TableCell>
                        <TableCell>Item Description</TableCell>
                        <TableCell align="right">Previous Quantity</TableCell>
                        <TableCell align="right">Unit Price</TableCell>
                        <TableCell align="right">Tax Rate</TableCell>
                        <TableCell align="right" sx={{width: '140px'}}>Desired Quantity</TableCell>
                        <TableCell align="right" sx={{width: '110px'}}>Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.lineItems.map(item => (
                        <LineItemRow form={form} item={item} key={item.id}/>
                    ))}
                </TableBody>
                <LineItemFooter form={form} customer={customer}/>
            </Table>
        </TableContainer>
    );
};

export default LineItemTable;
