import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToCustomer} from '@/mappers/customer';
import type {Customer, RawCustomer} from '@/types/customer';
import {apiUrl} from '@/utils/api';

class AssignedCustomerNotFoundError extends Error {
}

export const useCustomerQuery = (customerId = 'self') : UseQueryResult<Customer> => {
    const fetch = useOidcFetch();

    return useQuery(['customer', customerId], async ({signal}) => {
        const response = await fetch(apiUrl(`/customers/${customerId}`).toString(), {
            signal,
        });

        if (response.status === 404) {
            throw new AssignedCustomerNotFoundError(`Customer with ID ${customerId} not available`);
        }

        if (!response.ok) {
            throw new Error(`Unable to fetch customer with ID ${customerId}`);
        }

        const raw = await response.json() as RawCustomer;
        return mapRawToCustomer(raw);
    });
};
