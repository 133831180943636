import {Container} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Outlet} from 'react-router-dom';
import AccountButton from '@/layout/AccountButton';

const Layout = () : JSX.Element => {
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" sx={{flexGrow: 1}}>
                        Ellis Coffee Delivery
                    </Typography>

                    <AccountButton sx={{displayPrint: 'none'}}/>
                </Toolbar>
            </AppBar>
            <Container component="main" sx={{mt: 4, mb: 4}}>
                <Outlet/>
            </Container>
        </>
    );
};

export default Layout;
