import {TableCell, TableFooter, TableRow} from '@mui/material';
import type {UseFormReturn} from 'react-hook-form';
import type {FormValues} from './OrderForm';
import type {Customer} from '@/types/customer';
import {currencyFormatter} from '@/utils/format';
import {formQuantitiesToOrderLineItems, mergeOrder} from '@/utils/order';

type Props = {
    form : UseFormReturn<FormValues>;
    customer : Customer;
};

const LineItemFooter = ({form, customer} : Props) : JSX.Element => {
    const rawQuantities = form.watch('quantities') as unknown as Record<string, string> | undefined ?? {};
    const mergedOrder = mergeOrder(customer, formQuantitiesToOrderLineItems(rawQuantities));

    return (
        <TableFooter>
            <TableRow>
                <TableCell colSpan={6} align="right">
                    Subtotal:
                </TableCell>
                <TableCell align="right">
                    {currencyFormatter.format(mergedOrder.subtotal / 100)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={6} align="right">
                    Sales Tax:
                </TableCell>
                <TableCell align="right">
                    {currencyFormatter.format(mergedOrder.salesTax / 100)}
                </TableCell>
            </TableRow>
            {mergedOrder.fuelSurcharge > 0 && (
                <TableRow>
                    <TableCell colSpan={6} align="right">
                        Fuel Surcharge:
                    </TableCell>
                    <TableCell align="right">
                        {currencyFormatter.format(mergedOrder.fuelSurcharge / 100)}
                    </TableCell>
                </TableRow>
            )}
            {mergedOrder.smallOrderFee > 0 && (
                <TableRow>
                    <TableCell colSpan={6} align="right">
                        Fee on orders below {currencyFormatter.format(customer.smallOrderFee.limit / 100)}:
                    </TableCell>
                    <TableCell align="right">
                        {currencyFormatter.format(mergedOrder.smallOrderFee / 100)}
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell colSpan={6} align="right" sx={{borderBottom: 'none'}}>
                    <strong>Order Total:</strong>
                </TableCell>
                <TableCell align="right" sx={{borderBottom: 'none'}}>
                    <strong>{currencyFormatter.format(mergedOrder.final / 100)}</strong>
                </TableCell>
            </TableRow>
        </TableFooter>
    );
};

export default LineItemFooter;
