import type {Customer, LineItem} from '@/types/customer';
import type {OrderLineItem} from '@/types/order';

export type MergedLineItem = LineItem & {
    quantity : number;
    totalPrice : number;
};

export type MergedOrder = {
    lineItems : MergedLineItem[];
    subtotal : number;
    salesTax : number;
    fuelSurcharge : number;
    smallOrderFee : number;
    final : number;
};

export const formQuantitiesToOrderLineItems = (quantities : Record<string, string>) : OrderLineItem[] => {
    return Object.entries(quantities).map(([id, rawQuantity]) => {
        const quantity = parseInt(rawQuantity, 10);

        return {
            id: id.replace(/^item_/, ''),
            quantity: Number.isNaN(quantity) ? 0 : quantity,
        };
    });
};

export const mergeOrder = (customer : Customer, quantities : OrderLineItem[]) : MergedOrder => {
    const lineItems = customer.lineItems.map(item => {
        const quantity = quantities.find(quantity => quantity.id === item.id)?.quantity ?? 0;
        return {...item, quantity: quantity, totalPrice: quantity * item.lastPrice};
    }).filter(item => item.quantity > 0);

    const subtotal = lineItems.reduce((subtotal, item) => subtotal + item.totalPrice, 0);
    const salesTax = lineItems.reduce((salesTax, item) => salesTax + item.totalPrice * item.taxRate.toNumber(), 0);
    const fuelSurcharge = customer.fuelSurcharge ?? 0;
    const smallOrderFee = subtotal < customer.smallOrderFee.limit ? customer.smallOrderFee.fee : 0;

    return {
        lineItems,
        subtotal,
        salesTax,
        fuelSurcharge,
        smallOrderFee,
        final: subtotal + salesTax + fuelSurcharge + smallOrderFee,
    };
};
